@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Futura Book";
  src: url("./assets/fonts/FuturaBook.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

* {
  @apply font-futuraBook;
}

h1,
h2,
h3,
h4 {
  font-weight: bold;
}
